export default {
	name: "Glossary",
	created () {
		this.$nextTick(function () {
			this.relevantData('A', 0)
			this.GitGlossarySeek(this.GlossaryData[0])
			this.ariseCombobox('#comboboxGlossary')
		});
	},
	mounted () {
		this.$nextTick(function () {
			// this.$$.navbarAnimation()
		})
	},
	data () {
		return {
			GlossaryShwoData: [],
			GlossaryData: [
				[{
					letter: 'A',
					data: [{
						name: 'ALPHA',
						content: `Alpha is a measure of the performance of an investment as compared to a suitable benchmark index. An alpha of one (the baseline value is zero) shows that the return on the investment during a specified time frame outperformed the overall market average by 1%. A negative alpha number reflects an investment that is underperforming as compared to the market average.`
					}, {
						name: 'AMERICAN-STYLE',
						content: 'A structured warrant that may be exercised on any market day up to and including the expiry date.'
					},
					{
						name: 'ASK VOLUME',
						content: 'Ask volume is the quantity of a security that sellers are willing to sell at a specified ask price. Ask volume is stated as board lots with each representing 100 units each.'
					},
					{
						name: 'ASK PRICE',
						content: 'Ask price is the lowest price a seller is willing to sell a particular security.'
					},
					{
						name: 'AT-THE-MONEY',
						content: `A situation where the structured warrant's exercise price is close to or identical to the underlying asset price. An at-the-money structured warrant has no intrinsic value, but may still have some time value (depends on time to maturity). <br/>For example if XYZ share is trading at IDR500, then the structured warrant with the exercise price of IDR500 are said to be at-the-money.`
					}
					]
				},
				{
					letter: 'B',
					data: [{
						name: 'BEAR MARKET',
						content: `A share market in which sellers dominate, resulting in generally falling prices.`
					},
					{
						name: 'BID VOLUME',
						content: `Bid volume is the quantity of a security that buyers are willing to buy at a specified bid price. Bid volume is stated as board lots with each representing 100 units each.`
					},
					{
						name: 'BID PRICE',
						content: `Bid price is the highest price a buyer is willing to purchase a particular security.`
					},
					{
						name: 'BLACK-SCHOLES MODEL',
						content: `One of the most commonly used option pricing models.`
					},
					{
						name: 'BOARD LOT',
						content: `The minimum number of structured warrants to trade on the Indonesia Stock Exchange (IDX). 1 board lot = 100 units of structured warrants.`
					},
					{
						name: 'BONUS SHARES',
						content: `Bonus shares are shares distributed by a company to its current shareholders as fully paid shares free of charge. Instead of a dividend payout, companies offer to distribute additional shares to the shareholders. For example, the company may decide to give out one bonus share for every ten shares held by existing shareholders.`
					},
					{
						name: 'BROKER',
						content: `An agent, authorised to buy and sell securities on behalf of a client.`
					},
					{
						name: 'BULL MARKET',
						content: `A share market in which buyers dominate and where prices are on a rising trend.`
					},
					{
						name: 'BREAK-EVEN LEVEL',
						content: `Refers to the price at which a transaction produces neither a gain nor a loss.`
					}
					]
				},
				{
					letter: 'C',
					data: [{
						name: 'CALL WARRANTS',
						content: `A call warrant offers its holder the right, but not obligation to purchase a certain amount of the underlying asset on a pre-determined exercise price within a pre-determined time.`
					},
					{
						name: 'CASH SETTLEMENT AMOUNT',
						content: `The positive amount received from the exercise of the structured warrant at expiry. In the event the cash settlement amount is less than or equal to zero, structured warrantholders shall not be entitled to receive any payment in respect of the structured warrants.`
					},
					{
						name: 'CUSTOMER FUND ACCOUNT',
						content: `Customer Fund Account, commonly known as <i>Rekening Dana Nasabah ("RDN")</i> is a fund account in the name of a customer of a bank appointed by the Indonesia Central Securities Depository or <i>PT Kustodian Sentral Efek Indonesia ("KSEI")</i> in accordance with applicable laws and regulations, which is administered by a Broker-Dealer based on the power of attorney or instructions from the customer.`
					}
					]
				},
				{
					letter: 'D',
					data: [{
						name: 'DELTA',
						content: `Delta is the ratio of the change in the structured warrant to the change in the underlying asset’s price. It shows the absolute changes in the price of the structured warrant, if the price of the underlying asset changes. <br/><br/>Delta normally ranges between 0 and 1 for call warrants and 0 and -1 for put warrants. For example (assuming a structured warrant with conversion ratio of 1) a delta of 0.50 implies that if the value of the underlying changes by IDR100, then the value of the call warrant should change by IDR50.`
					},
					{
						name: 'DISCOUNT',
						content: `The amount by which the price of a share is quoted below its paid-up value.`
					},
					{
						name: 'DIVIDEND YIELD',
						content: `The amount of a company's annual dividend expressed as a percentage of the current price of the share of that company.`
					},
					{
						name: 'DISCLAIMER',
						content: `You agree that the use of the Service is at your sole risk and acknowledge that the Service and anything contained within the Service are provided “as is” and PT Maybank Sekuritas Indonesia ("Maybank Sekuritas") makes no representation or warranty of any kind, express or implied, as to the merchantability, timeliness, accuracy, non-infringement, title or fitness for a particular purpose or use or any representations or warranties arising from usage or custom or trade or by operation of law.<br/><br/> 
Maybank Sekuritas, its employees, directors, agents, service providers and Third Party Content Providers shall not be liable for any loss of data whatsoever, including any inadvertent corruption, erasure of data transmitted or received or stored on this Site, mistakes, omissions, interruptions or deletion of files, errors, defects, delays in operation, or transmission, or any failure of performance, whether or not limited to Acts of God, communication failure, theft, destruction or unauthorised access to Maybank Sekuritas’s records or the Site.<br/><br/>
In no event shall Maybank Sekuritas, its employees, directors, agents, service providers or Third Party Content Providers be liable in respect of any claim arising out of or relating to the Site, Service or its Contents, including, but not limited to, (i) any decision made or action taken by any users in reliance upon such Content, (ii) any access, use or inability to access or use this Site, (iii) any system or server or connection failure, error or omission, interruption, delay in transmission, or computer virus; and/or (iv) any use of or access to any other website linked to this Site, or for any damages suffered, whether direct, consequential, special, punitive, indirect or otherwise, notwithstanding having been advised of the possibility of such damages.<br/><br/>
This exclusion clause shall take effect to the fullest extent permitted by law.`
					}
					]
				},
				{
					letter: 'E',
					data: [{
						name: 'EARNINGS PER SHARE',
						content: `The amount of a company's annual profits or earning attributable to each ordinary share of that company.`
					},
					{
						name: 'EFFECTIVE GEARING',
						content: `Measures the theoretical percentage change in structured warrant price for every 1% change in the underlying price. For example, effective gearing of 10 times, other factors being equal, means for every 1% change in underlying price, the structured warrant price moves by 10%.`
					},
					{
						name: 'EXCHANGE TRADING HOURS',
						content: `To note: The equities trading hours are divided into two sessions for both normal and odd lot market.<br/>
						For Indonesia: the first session is from 9.00am - 11.30am, and the second session runs from 1.30pm to 14.50pm. <br/>
						For Malaysia: The first session is from 8.30am – 12.30pm, and the second session runs from 2.00pm – 5.00pm.<br/>
						For Hong Kong: The first session is from 9:00am - 12:00pm, and the second session runs from 1:00pm - 4:00pm.<br/>
						For Thailand: The first session open at random between 9:55 to 10:00am - 12:30pm, and the second session open at random between 2:25 to 2:30pm and closes at random between 4:35 to 4:40pm.`
					},
					{
						name: 'EUROPEAN STYLE',
						content: `A structured warrant that is automatically exercised on the predetermined expiry date.`
					},
					{
						name: 'EXERCISE PRICE/LEVEL',
						content: `The predetermined price/level for the structured warrantholder to buy or sell the underlying of the structured warrant. It is used to calculate the cash settlement amount of a cash-settled structured warrant at the expiry.`
					},
					{
						name: 'EXERCISE RATIO',
						content: `The number of structured warrants needed to exchange for one unit of the underlying asset.<br/><br/>

						For example, a XYZ call warrant with a ratio of 5 call warrants : 1 XYZ share would indicate that 5 call warrants are needed to exercise the right to buy one XYZ share at the exercise price.<br/><br/> 
																																																																																																																D
						For structured warrants linked to the price of shares, the ratio may be adjusted to reflect corporate actions, such as bonus shares issuance, stock splits, rights issues or share consolidations.`
					},
					{
						name: 'EXPIRY DATE',
						content: `The date the structured warrant expires; where the right of the structured warrantholder to exercise the warrant ceases.`
					}
					]
				},
				{
					letter: 'F',
					data: [{
						name: 'FUTURES',
						content: `Futures are financial contracts obligating the buyer to purchase an asset or the seller to sell an asset on a predetermined future date and price.`
					}]
				},
				{
					letter: 'G',
					data: [{
						name: 'GAMMA',
						content: `Measures the changes of a structured warrant's delta as the price of the underlying asset changes, all other factors remain constant.`
					},
					{
						name: 'GEARING',
						content: `Gearing is the ratio of the share price to the structured warrant price and represents the leverage that the structured warrant offers.<br/><br/>

						For example, a structured warrant with gearing of 10x will have 10 times more exposure than if you purchased the underlying asset. To estimate the increase or decrease in the structured warrant price in relative to the underlying asset price, we should look at effective gearing.`
					},
					{
						name: 'GROWTH STOCKS',
						content: `Shares of companies with good prospects for increasing profits and capital size. Likely to bring shareholders future capital gains through a share price rise, high dividends, share bonuses or rights issues.`
					}
					]
				},
				{
					letter: 'H',
					data: [{
						name: 'HEDGE',
						content: `Hedging is a strategy to reduce the risk of adverse price movements in an asset. Normally, a hedge consists of taking an offsetting position in a related security to protect the value of an asset or a portfolio.`
					},
					{
						name: `DAY'S HIGH/ HIGH PRICE`,
						content: `High price refers to a security's intraday high trading price. High price indicates the security's highest price traded throughout one day of trading activity during regular trading hours.`
					},
					{
						name: 'HISTORICAL VOLATILITY',
						content: `Used as a reference to assess the previous price fluctuations of the underlying asset. Normally expressed in number of days, e.g. 10-day volatility, 90-day volatility etc.`
					}
					]
				},
				{
					letter: 'I',
					data: [{
						name: `IMPLIED VOLATILITY`,
						content: `Most important factor that affects the price of structured warrants. It refers to the estimate of future price volatility of a specified underlying and is used by the market as an indicator to decide whether a structured warrant is cheap or expensive. With all factors being equal, the higher the implied volatility, the higher the structured warrant price.<br/><br/>

						For example, a IDR500 structured warrant that fluctuates at IDR50 has higher implied volatility than a IDR500 structured warrant that fluctuates at IDR5.`
					},
					{
						name: `INDICES`,
						content: `In relation to a stock exchange, index is a measures of a share market, or a subset of the share market, that helps investors compare current price levels with past prices to calculate market performance.`
					},
					{
						name: `INITIAL PUBLIC OFFERING (IPO)`,
						content: `An initial public offering (IPO) occurs when a company first sells common shares to investors in the public. When the company starts to offer its shares through IPOs, they are required to reveal financial information and all relevant material information about the company so that investors can make an informed decision.`
					},
					{
						name: `INSTRUMENT`,
						content: `A tradeble asset or negotiable item such as a security, commodity, derivative or index, or any item that underlies a derivative. An instrument is a means by which something of value is transferred or held.`
					},
					{
						name: `IN-THE-MONEY`,
						content: `An in-the-money option has intrinsic value more than zero.<br/><br/>

						For <strong>call</strong> warrant, it is said to be in-the-money if Underlying Price > Exercise Price;<br/>
						For <strong>put</strong> warrant, it is said to be in-the-money if Exercise Price > Underlying Price<br/>.`
					},
					{
						name: `INTRADAY MOVEMENT`,
						content: `Intraday is defined as within the day. Intraday movement refers to the price movements of a specific security throughout one day of trading activity during regular trading hours. It is to express the high and low price of a security in its trading day.`
					},
					{
						name: `INTRINSIC VALUE`,
						content: `Intrinsic value for a structured warrant is the difference between the price of the underlying asset and the exercise price. The intrinsic value can be zero, but it can never be negative.`
					},
					{
						name: `ISSUER`,
						content: `The entity that issues structured warrants, usually a financial institution, e.g. Maybank Sekuritas.`
					},
					{
						name: `INTRADAY'S LOW/LOW PRICE`,
						content: `Intraday low refers to the lowest price at which a security trades on a given trading day. It can be lower than the closing price and is also called the Day low.`
					}
					]
				},
				{
					letter: 'J',
					data: []
				},
				{
					letter: 'K',
					data: []
				},
				{
					letter: 'L',
					data: [{
						name: `LAST PRICE`,
						content: `Last price refers to the last traded price of the particular security. Last price represents at what price points there are willing buyers and sellers and where the most recent trade occured.`
					},
					{
						name: `LAST TRADING DAY`,
						content: `For regular and negotiated market, three (3) market days immediately before the expiry date of the structured warrant. For cash market, one (1) market day before the expiry date of the structured warrant.`
					},
					{
						name: `LIMIT ORDER`,
						content: `To avoid buying or selling a security at a price higher or lower than what is wanted, it's best to place a limit order rather than a market order. A limit order is an order to buy or sell an instrument at a specific price. A buy limit order can only be executed at the limit price or lower, and a sell limit order can only be executed at the limit price or higher. When a market order is placed, the price at which an order will be filled can't be controlled.`
					},
					{
						name: `LIQUIDITY OF THE MARKET`,
						content: `The state of affairs in a share market in which it is generally easy to convert securities into cash and vice versa, without causing a movement in prices.`
					},
					{
						name: `LIQUIDITY`,
						content: `A structured warrant is considered liquid when there is:-<br/><br/>

						i. Adequate trading volume; and/ or<br/>
						ii. Sufficient depth in bid and ask price; and/ or<br/>
						iii. Adequate quantity during trading hours.`
					},
					{
						name: `LISTING DAY`,
						content: `The day the structured warrant is listed and quoted on the exchange.`
					},
					{
						name: `LIQUIDITY PROVIDER`,
						content: `Refer to MARKET MAKER.`
					},
					{
						name: `LIVE MATRIX`,
						content: `Live matrix is a live price matrix feed from Maybank Sekuritas's market making system. It allows investors to view bids and offer prices for a structured warrant based on various price points in the underlying asset.<br/><br/>

						The structured warrant prices in the Live Matrix are indicative. The actual warrant prices on the market may differ from the prices displayed on the website.`
					},
					{
						name: `INTRADAY'S LOW/LOW PRICE`,
						content: `Intraday low refers to the lowest price at which a security trades on a given trading day. It can be lower than the closing price and is also called the Day low.`
					}
					]
				},
				{
					letter: 'M',
					data: [{
						name: `MARKET CAPITALISATION`,
						content: `The total value of a listed company's shares based on current market price.`
					},
					{
						name: `MARKET MAKER`,
						content: `Market maker provides liquidity by posting bid and offer prices on the trading system of Indonesia Stock Exchange on each market day over the life of the structured warrant. Market maker can be the issuer itself (e.g. Maybank Sekuritas) or an appointed designated market maker.`
					},
					{
						name: `MARKET ORDER`,
						content: `A market order is an order to buy or sell a security at the current market price. The advantage of a market order is that it is almost always guaranteed that the order will be executed (as long as there are willing buyers and sellers). `
					},
					{
						name: `MONEYNESS`,
						content: `Relationship between exercise price of the structured warrants and the underlying asset price. There are three forms of moneyness, which are <strong>In-the-Money</strong>, <strong>At-the-Money</strong> and <strong>Out-of-the-Money</strong>.`
					}
					]
				},
				{
					letter: 'N',
					data: []
				},
				{
					letter: 'O',
					data: [{
						name: `ODD LOTS`,
						content: `This is an amount of security that is smaller than the usual unit of trading in Indonesia; specifically, a quantity of less than 100 units in a transaction or a trade that is not divisible by 100.`
					},
					{
						name: `OPEN PRICE`,
						content: `Open price is the price at which a security first trades upon the opening of an exchange on a trading day. For example, Indonesia Stock Exchange opens at precisely 9:00a.m, GMT+7. The price of the first trade for any listed security is its daily open price.`
					},
					{
						name: `ORDER BOOK`,
						content: `Order Book is an 'electronic book' that shows the demand for the security at various prices on a real time basis.`
					},
					{
						name: `OUT-OF-THE-MONEY`,
						content: `An out-of-the-money option has intrinsic value equals to zero.<br/><br/>

						For <strong>call</strong> warrant, it is said to be out-of-the-money if Underlying Price < Exercise Price;<br/>
						For <strong>put</strong> warrant, it is said to be out-of-the-money if Exercise Price < Underlying Price.`
					},
					{
						name: `OUTSTANDING STRUCTURED WARRANT QUANTITY`,
						content: `Outstanding structured warrant quantity refers to the quantity of structured warrants held by investors. Hedging by the issuer will only be executed for its outstanding positions. The higher the outstanding quantity, the higher the probability that the structured warrant price is affected by the forces of market supply and demand and vice versa. As a result, the structured warrant's price may not track the price of the underlying asset very closely.<br/><br/>

						For Indonesia, to observe Maybank Sekuritas's outstanding structured warrant quantity for any specified structured warrant (available for each calendar month), investors may refer to the Announcement tab under the News section for warrants on Indonesia Stock Exchange's website.`
					}
					]
				},
				{
					letter: 'P',
					data: [{
						name: `PREMIUM`,
						content: `Measures the percentage of the underlying asset has to rise (Call Warrant) or fall (Put Warrant) for a structured warrantholder to become break-even if he/she holds the structured warrant until expiry.`
					},
					{
						name: `PROSPECTUS`,
						content: `The document to be issued by a company intending to make an issue of shares to the public.`
					}, {
						name: `PUT WARRANT`,
						content: `A put warrant offers its holder the right, but not obligation to sell a certain amount of the underlying asset on a pre-determined exercise price within a pre-determined time.`
					}
					]
				},
				{
					letter: 'Q',
					data: [{
						name: `QUOTATION`,
						content: `The prices of bid and offered by buyers and sellers for a security listed on a stock exchange.`
					}]
				},
				{
					letter: 'R',
					data: [{
						name: `RIGHTS ISSUE`,
						content: `Companies raise additional capital by offering to existing shareholders the rights to subscribe for new shares, at a price usually below the current market price. These rights  have a market of their own and can be traded on the stock exchange.`
					}
					]
				},
				{
					letter: 'S',
					data: [{
						name: `SHARE SPLIT`,
						content: `When a company reduces the par value of its shares by increasing the number of outstanding share in the market, e.g. 100,000 units of IDR500 ordinary shares would be split into 200,000 units of IDR250 ordinary shares.`
					},
					{
						name: `SHORT SELLING`,
						content: `The action of a person selling securities which he does not own at the time of selling.`
					},
					{
						name: `STRUCTURED WARRANT`,
						content: `Securities which gives the structured warrantholder the right to buy or sell the underlying asset at a predetermined price and time. `
					},
					{
						name: `STRUCTURED WARRANT CALCULATOR`,
						content: `Structured Warrant calculator tool allows investors to gauge the theoretical price of a specific structured warrant based on a few inputs. Inputs needed are such as underlying price, date to maturity and/or implied volatility.<br/><br/>

						Investors can gauge the price of the structured warrant based on their own perceived view of the movement in the underlying price and/or implied volatility while factoring in holding period of the structured warrant.`
					},
					{
						name: `STRUCTURED WARRANTS TRADING HOURS`,
						content: `Structured warrants trading hours would follows equity trading hours.`
					},
					{
						name: `STRUCTURED WARRANTHOLDER`,
						content: `Public who invests in the structured warrants and their names are recorded in the list of structured warrants holders.`
					},
					{
						name: `STRUCTURED WARRANTS PROSPECTUS`,
						content: `The offering document published by an Issuer in connection with the offering of structured warrants to be issued from time to time.`
					},
					{
						name: `STOP ORDER`,
						content: `A stop order becomes a market order to buy or sell securities once the specified stop price is attained or penetrated.`
					},
					]
				},
				{
					letter: 'T',
					data: [{
						name: `THETA`,
						content: `Measures the expected daily decline in the value of a structured warrant due to time decay, all other factors remain constant. Theta is always negative as time decay is always working against a structured warrantholder.`
					},
					{
						name: `TIME DECAY`,
						content: `Time decay is the effects of time value declining as structured warrants move towards expiration.`
					},
					{
						name: `TIME VALUE`,
						content: `The difference between the structured warrant price and intrinsic value.`
					}
					]
				},
				{
					letter: 'U',
					data: [{
						name: `UNDERLYING ASSET`,
						content: `The financial instrument referenced by the structured warrant.<br/><br/>

						For example,
						The underlying asset of XYZ-CA is the XYZ shares;<br/>
						The underlying asset of FBMKLCI-C9K is the KLCI Index.`
					}]
				},
				{
					letter: 'V',
					data: [{
						name: `VOLATILITY`,
						content: `The uncertainty in the price movement which will affect the return of the underlying asset.<br/><br/>

						Refer to HISTORICAL VOLATILITY and IMPLIED VOLATILITY`
					},
					{
						name: `VOLUME TRADED`,
						content: `Volume traded refers to the total quantity of the specified security transacted between buyers and sellers  during a trading day throughout its regular trading hours.`
					},
					{
						name: `VOLUME WEIGHTED AVERAGE PRICE (VWAP)`,
						content: `The VWAP for a security is calculated by adding the value traded for every transaction in that share ("price" x "number of security traded") and dividing it with the total number of security traded. A VWAP can be computed for any given trading period.`
					}
					]
				},
				{
					letter: 'W',
					data: [{
						name: `WARRANTS`,
						content: `A warrant gives the holder the right but not the obligation to buy an underlying security at a certain price and time.`
					}
					]
				},
				{
					letter: 'X',
					data: []
				},
				{
					letter: 'Y',
					data: []
				},
				{
					letter: 'Z',
					data: []
				}
				],
				[{
					letter: 'A',
					data: [{
						name: `ALPHA`,
						content: `Alpha adalah ukuran kinerja investasi dibandingkan dengan indeks acuan yang sesuai. Alpha satu (nilai dasar adalah nol) menunjukkan bahwa pengembalian investasi selama kerangka waktu tertentu memiliki kinerja yang lebih baik dari rata-rata pasar secara keseluruhan sebesar 1%. Angka alpha negatif mencerminkan investasi yang memiliki kinerja yang lebih buruk dibandingkan dengan rata-rata pasar.`
					},
					{
						name: `<i>AMERICAN STYLE</i>`,
						content: `Waran terstruktur yang dapat dilaksanakan pada setiap hari pasar sampai dan termasuk tanggal pelaksanaan. `
					},
					{
						name: `<i>AT-THE-MONEY</i>`,
						content: `Situasi di mana harga pelaksanaan waran terstruktur mendekati atau identik dengan harga aset yang mendasarinya. Waran terstruktur at-the-money tidak memiliki nilai intrinsik, tetapi mungkin masih memiliki nilai waktu tertentu (tergantung pada waktu jatuh tempo). 
						Misalnya jika saham XYZ diperdagangkan pada harga Rp500, maka waran terstruktur dengan harga pelaksanaan sebesar Rp500 dikatakan <i>at-the-money</i>.`
					},
					{
						name: `ASET YANG MENDASARI`,
						content: `Instrumen keuangan yang dirujuk oleh waran terstruktur.`
					}
					]
				},
				{
					letter: 'B',
					data: [{
						name: `BUKU PESANAN`,
						content: `Buku Pesanan adalah 'buku elektronik' yang menunjukkan permintaan efek pada berbagai harga secara real time.`
					},

					]
				},
				{
					letter: 'C',
					data: [
						{
							name: ` WARAN JENIS <i>CALL</i>`,
							content: `Waran jenis <i>Call</i> menawarkan hak kepada pemegangnya, tetapi bukan kewajiban untuk membeli sejumlah aset yang mendasari tertentu pada harga pelaksanaan yang telah ditentukan sebelumnya dalam waktu yang telah ditentukan sebelumnya.`
						},
					]
				},
				{
					letter: 'D',
					data: [{
						name: `DELTA`,
						content: `Delta adalah rasio konversi dalam waran terstruktur terhadap perubahan harga aset yang mendasarinya. Delta menunjukkan perubahan absolut dalam harga waran terstruktur, jika harga aset yang mendasarinya berubah. <br/><br/>
						Delta biasanya berkisar antara 0 dan 1 untuk waran jenis <i>call</i> dan 0 dan -1 untuk waran jenis <i>put</i>. Misalnya (dengan asumsi waran terstruktur dengan rasio konversi 1) delta 0,50 menyiratkan bahwa jika nilai aset yang mendasarinya berubah sebesar IDR100, maka nilai waran jenis <i>call</i> seharusnya berubah sebesar IDR50.`
					},
					{
						name: `DISKON`,
						content: `Jumlah yang digunakan untuk mengutip harga saham di bawah nilai disetornya.`
					},
					]
				},
				{
					letter: 'E',
					data: [
						{
							name: `<i>Effective Gearing</i>`,
							content: `Mengukur persentase perubahan teoritis dalam harga waran terstruktur untuk setiap perubahan sebesar 1% dalam harga aset yang mendasarinya. Misalnya, <i>effective gearing</i> sebesar 10 kali, dengan faktor lain yang sama, berarti untuk setiap perubahan sebesar 1% dalam harga aset yang mendasarinya, harga waran terstruktur bergerak sebesar 10%.`
						},
						{
							name: `<i>EUROPEAN STYLE</i>`,
							content: `Waran terstruktur yang secara otomatis dilaksanakan pada tanggal pelaksanaan yang telah ditentukan sebelumnya.`
						},
					]
				},
				{
					letter: 'F',
					data: []
				},
				{
					letter: 'G',
					data: [{
						name: `GAMMA`,
						content: `Mengukur perubahan delta waran terstruktur saat harga aset yang mendasarinya berubah, semua faktor lainnya tetap konstan.`
					},
					{
						name: `<i>GEARING</i>`,
						content: `Pengungkit Gearing adalah rasio harga saham terhadap harga waran terstruktur dan mewakili leverage yang ditawarkan waran terstruktur.<br/><br/>Misalnya, waran terstruktur dengan pengungkit gearing 10x akan memiliki eksposur 10 kali lebih besar daripada jika aAnda membeli aset yang mendasarinya. Untuk memperkirakan kenaikan atau penurunan harga waran terstruktur relatif terhadap harga aset yang mendasarinya, kita harus melihat efek pengungkit effective gearing.`
					},
					]
				},
				{
					letter: 'H',
					data: [{
						name: `HARGA PERMINTAAN`,
						content: `Harga permintaan adalah harga terendah penjual bersedia menjual efek tertentu.`
					},
					{
						name: `HARGA PENAWARAN`,
						content: `Harga penawaran adalah harga tertinggi pembeli bersedia membeli efek tertentu.`
					},
					{
						name: `HASIL DIVIDEN`,
						content: `Jumlah dividen tahunan perusahaan yang dinyatakan sebagai persentase dari harga saham perusahaan tersebut saat ini.`
					},
					{
						name: `HARGA/TINGKAT PELAKSANAAN`,
						content: `Harga/tingkat yang telah ditentukan sebelumnya bagi pemegang waran terstruktur untuk membeli atau menjual aset yang mendasari dari waran terstruktur tersebut. Harga/tingkat pelaksanaan digunakan untuk menghitung jumlah penyelesaian tunai dari waran terstruktur yang diselesaikan secara tunai pada tanggal pelaksanaan.`
					},
					{
						name: `HARGA TINGGI / TERTINGGI HARI INI`,
						content: `Harga tinggi merujuk kepada harga perdagangan tinggi intraday dari efek. Harga tinggi menunjukkan harga tertinggi dari efek yang diperdagangkan selama satu hari aktivitas perdagangan selama jam perdagangan reguler.`
					},
					{
						name: `HARGA TERAKHIR`,
						content: `Harga terakhir mengacu pada harga terakhir dari efek tertentu yang diperdagangkan. Harga terakhir mewakili titik harga di mana terdapat pembeli dan penjual yang bersedia dan di mana perdagangan yang terakhir terjadi.`
					},
					{
						name: `HARI PERDAGANGAN TERAKHIR`,
						content: `Untuk pasar reguler dan negosiasi, tiga (3) hari pasar yang tepat sebelum tanggal pelaksanaan dari waran terstruktur. Untuk pasar tunai, satu (1) hari pasar sebelum tanggal pelaksanaan dari waran terstruktur.`
					},
					{
						name: `HARI PENCATATAN`,
						content: `Hari yang pada hari tersebut waran terstruktur dicatat dan dikutip di bursa.`
					},
					{
						name: `HARGA RENDAH/RENDAH <i>INTRADAY</i>`,
						content: `Rendah intraday merujuk kepada harga terendah yang pada harga tersebut efek diperdagangkan pada suatu hari perdagangan. Rendah intraday bisa lebih rendah dari harga penutupan dan juga disebut rendah Hari.`
					},
					{
						name: `HARGA PEMBUKAAN`,
						content: `Harga pembukaan adalah harga yang pada harga tersebut efek pertama kali diperdagangkan pada saat pembukaan bursa pada hari perdagangan. Misalnya, Bursa Efek Indonesia dibuka tepat pukul 09.00, GMT+7. Harga perdagangan pertama untuk setiap efek yang tercatat adalah harga pembukaan hariannya.`
					},
					{
						name: `HAK MEMESAN EFEK TERLEBIH DAHULU (RIGHTS ISSUE)`,
						content: `Perusahaan meningkatkan modal tambahan dengan menawarkan kepada pemegang saham yang ada hak untuk memesan saham baru, pada harga yang biasanya di bawah harga pasar saat ini. Hak ini memiliki pasarnya sendiri dan dapat diperdagangkan di bursa efek.`
					},
					{
						name: `HARGA RATA-RATA TERTIMBANG VOLUME (VWAP)	`,
						content: `VWAP untuk efek dihitung dengan menambahkan nilai yang diperdagangkan untuk setiap transaksi dalam saham tersebut ("harga" x "jumlah efek yang diperdagangkan") dan membaginya dengan jumlah total efek yang diperdagangkan. VWAP dapat dihitung untuk suatu periode perdagangan.`
					},
					]
				},
				{
					letter: 'I',
					data: [{
						name: `INDEKS`,
						content: `Dalam kaitannya dengan bursa efek, indeks adalah ukuran pasar saham, atau bagian dari pasar saham, yang membantu investor membandingkan tingkat harga saat ini dengan harga masa lalu untuk menghitung kinerja pasar.`
					},
					{
						name: `INSTRUMEN`,
						content: `Aset yang dapat diperdagangkan atau item yang dapat dialihkan seperti efek, komoditas, derivatif atau indeks, atau item apa pun yang mendasari suatu derivatif. Instrumen adalah sarana yang dengannya sesuatu yang bernilai ditransfer atau dipegang.`
					},
					{
						name: `<i>IN-THE-MONEY<i/>`,
						content: `Opsi in-the-money memiliki nilai intrinsik lebih dari nol.                                                                                                                    d                                                                                                                                                                                                                                         Untuk call waran beli jenis call, dikatakan in-the-money jika Harga Aset yang Mendasari > Harga Pelaksanaan;
						Untuk waran jenis <i>put</i>, dikatakan in-the-money jika Harga Pelaksanaan > Harga Aset yang Mendasarinya.`
					},
					]
				},
				{
					letter: 'J',
					data: [{
						name: `JAM PERDAGANGAN BURSA`,
						content: `Untuk diketahui: Jam perdagangan ekuitas dibagi menjadi dua sesi untuk pasar lot normal dan ganjil.<br/>         
						Untuk Indonesia: sesi pertama dimulai pukul 09.00 - 11.30, dan sesi kedua berlangsung dari pukul 13.30 sampai dengan 14.50. <br/>  
						Untuk Malaysia: Sesi pertama dimulai pukul 08.30 – 12.30, dan sesi kedua berlangsung dari pukul 14.00 – 17.00.<br/>  
						Untuk Hong Kong: Sesi pertama dimulai pukul 09.00 - 12.00, dan sesi kedua berlangsung dari pukul 13.00 - 16.00.<br/>  
						Untuk Thailand: Sesi pertama dibuka secara acak antara pukul 9:55 sampai dengan 10:00 - 12:30, dan sesi kedua dibuka secara acak antara pukul 14:25 sampai dengan 14:30 dan ditutup secara acak antara pukul 16:35 sampai dengan 16:40.`
					},
					{
						name: `JUMLAH WARAN TERSTRUKTUR YANG BEREDAR`,
						content: `Jumlah waran terstruktur yang beredar merujuk kepada jumlah waran terstruktur yang beredar yang dipegang oleh investor. Lindung nilai oleh penerbit hanya akan dilaksanakan untuk posisi yang beredar daripadanya. Semakin tinggi jumlah yang beredar, semakin tinggi probabilitas bahwa harga waran terstruktur dipengaruhi oleh kekuatan penawaran dan permintaan pasar dan sebaliknya. Akibatnya, harga waran terstruktur mungkin tidak mengikuti secara dekat harga aset yang mendasarinya.<br/><br/>
						  Untuk Indonesia, untuk mengamati jumlah waran terstruktur yang beredar dari Maybank Sekuritas  untuk setiap waran terstruktur tertentu (yang tersedia di setiap bulan kalender), investor dapat merujuk kepada tab Pengumuman di bawah bagian Berita untuk waran di situs web Bursa Efek Indonesia.`
					},
					{
						name: `JAM PERDAGANGAN WARAN TERSTRUKTUR`,
						content: `Jam perdagangan waran terstruktur akan mengikuti jam perdagangan ekuitas`
					},
					]
				},
				{
					letter: 'K',
					data: [{
						name: `KONTRAK BERJANGKA (BERJANGKA)`,
						content: `Berjangka adalah kontrak keuangan yang mewajibkan pembeli untuk membeli aset atau penjual untuk menjual aset pada tanggal dan harga di masa mendatang yang telah ditentukan sebelumnya.`
					},
					{
						name: `KAPITALISASI PASAR`,
						content: `Nilai total saham perusahaan tercatat berdasarkan harga pasar saat ini.`
					},
					{
						name: `KUTIPAN`,
						content: `Harga permintaan beli dan penawaran jual oleh pembeli dan penjual untuk efek yang tercatat di bursa efek.`
					},
					{
						name: `KALKULATOR WARAN TERSTRUKTUR`,
						content: `Alat kalkulator Waran Terstruktur memungkinkan investor untuk mengukur harga teoretis dari waran terstruktur tertentu berdasarkan beberapa input. Input yang dibutuhkan adalah seperti harga aset yang mendasarinya, tanggal jatuh tempo dan/atau volatilitas tersirat.<br/><br/>	
						 Investor dapat mengukur harga waran terstruktur berdasarkan pandangan mereka sendiri tentang pergerakan harga aset yang mendasarinya dan/atau volatilitas tersirat sambil memperhitungkan periode pemegangan waran terstruktur.`
					},
					]
				},
				{
					letter: 'L',
					data: [{
						name: `LOT`,
						content: `Jumlah minimum waran terstruktur untuk diperdagangkan di Bursa Efek Indonesia (BEI). 1 lot = 100 unit waran terstruktur.`
					},
					{
						name: `LINDUNG NILAI`,
						content: `Lindung nilai adalah strategi untuk mengurangi risiko pergerakan harga yang merugikan dalam suatu aset. Biasanya, lindung nilai terdiri dari pengambilan posisi perjumpaan dalam efek terkait untuk melindungi nilai aset atau portofolio.`
					},
					{
						name: `LIMIT ORDER`,
						content: `Untuk menghindari pembelian atau penjualan efek pada harga lebih tinggi atau lebih rendah dari yang diinginkan, adalah baik untuk menempatkan limit order daripada market order. Limit order adalah instruksi untuk membeli atau menjual instrumen pada harga tertentu. Limit order membeli hanya dapat dilaksanakan pada harga limit atau  yang lebih rendah, dan limit order menjual hanya dapat dilaksanakan pada harga limit atau yang lebih tinggi. Ketika market order ditempatkan, harga yang pada harga tersebut pesanan akan diisi tidak dapat dikendalikan.`
					},
					{
						name: `LIKUIDITAS PASAR`,
						content: `Keadaan di pasar saham di mana umumnya mudah untuk mengonversi efek menjadi uang tunai dan sebaliknya, tanpa menyebabkan pergerakan harga.`
					},
					{
						name: `LIKUIDITAS`,
						content: `Waran terstruktur dianggap likuid ketika ada<br/><br/>
						i. Volume perdagangan yang memadai; dan/ atau<br/>
						ii. Kedalaman yang cukup dalam harga permintaan beli dan penawaran jual; dan/ atau<br/>
						iii. Jumlah yang memadai selama jam perdagangan.`
					},
					{
						name: `LOT GANJIL`,
						content: `Ini adalah jumlah efek yang lebih kecil dari unit perdagangan biasa di Indonesia; secara khusus, jumlah yang kurang dari 100 unit dalam suatu transaksi atau perdagangan yang tidak dapat dibagi dengan 100.`
					},
					]
				},
				{
					letter: 'M',
					data: [
						{
							name: `MODEL BLACK-SCHOLESMODEL BLACK-SCHOLES`,
							content: `Salah satu model penetapan harga opsi yang paling umum digunakan.`
						},
						{
							name: `MATRIKS LANGSUNG`,
							content: `Matriks langsung merupakan umpan matriks harga langsung dari sistem pembuatan pasar Maybank Sekuritas. Matriks langsung memungkinkan investor untuk melihat harga permintaan beli dan penawaran jual untuk waran terstruktur berdasarkan berbagai titik harga dalam aset yang mendasarinya.<br/><br/>
						Harga waran terstruktur dalam Matriks Langsung bersifat indikatif. Harga waran aktual di pasar mungkin berbeda dari harga yang ditampilkan di situs web.`
						},
						{
							name: `<i>MARKET ORDER</i>`,
							content: `Market order adalah instruksi untuk membeli atau menjual efek pada harga pasar saat ini. Keuntungan dari market order adalah hampir selalu dijamin bahwa pesanan akan dilaksanakan (selama ada pembeli dan penjual yang bersedia).`
						},
						{
							name: `<i>MONEYNESS</i>`,
							content: `Hubungan antara harga pelaksanaan waran terstruktur dan harga aset yang mendasarinya. Ada tiga bentuk moneyness, yaitu <strong><i>In-the-Money</i><strong>, <strong><i>At-the-Money</i><strong> dan <strong><i>Out-of-the-Money</i><strong>.`
						},
					]
				},
				{
					letter: 'N',
					data: [{
						name: `NISBAH PERTUKARAN (EXERCISE RATIO)`,
						content: `Jumlah waran yang diperlukan untuk ditukarkan kepada satu unit saham pendasar. `
					},
					{
						name: `NIAGA HADAPAN (FUTURES)`,
						content: `Niaga hadapan adalah kontrak kewangan yang mengikat pembeli supaya membayar untuk sesuatu aset atau penjual bagi menjualkan asetnya.`
					},
					{
						name: `NILAI INTRINSIK (INTRINSIC VALUE)`,
						content: `Nilai intrinsik sesuatu waran adalah perbezaan di antara harga saham pendasar dan nilai laksana bagi waran tersebut. Nilai intrinsik boleh menjadi sifar tetapi tidak boleh menghasilkan nombor negatif.`
					},
					{
						name: `NILAI MASA (NILAI MASA)`,
						content: `Perbezaan antara harga waran dan nilai intrinsik.`
					},
					{
						name: `NILAI INTRINSIK`,
						content: `Nilai intrinsik untuk waran terstruktur adalah perbedaan antara harga dari aset yang mendasari dan harga pelaksanaan. Nilai intrinsik bisa nol, tetapi tidak pernah bisa negatif.`
					},
					{
						name: `NILAI WAKTU`,
						content: `Perbedaan antara harga waran terstruktur dan nilai intrinsik.`
					}
					]
				},
				{
					letter: 'O',
					data: [
						{
							name: `<i>OUT-OF-THE-MONEY</i>`,
							content: `Opsi out-of-the-money memiliki nilai intrinsik yang sama dengan nol.<br/><br/>
							Untuk waran jenis <i>call</i>, dikatakan out-of-the-money jika Harga Aset yang Mendasari < Harga Pelaksanaan;<br/>
							Untuk waran jenis <i>put</i>, dikatakan out-of-the-money jika Harga Pelaksanaan < Harga yang Mendasari.`
						},
					]
				},
				{
					letter: 'P',
					data: [{
						name: `PASAR BEAR`,
						content: `Pasar saham di mana penjual mendominasi, mengakibatkan harga secara umum turun.`
					},
					{
						name: `PIALANG`,
						content: `Agen, yang diberikan otorisasi untuk membeli dan menjual efek atas nama klien.`
					},
					{
						name: `PASAR <i>BULL</i>`,
						content: `Pasar saham di mana pembeli mendominasi dan di mana harga berada pada tren yang meningkat.`
					},
					{
						name: `PENGHASILAN PER SAHAM`,
						content: `Jumlah laba tahunan  atau penghasilan dari perusahaan yang berasal dari setiap saham biasa dari perusahaan tersebut.`
					},
					{
						name: `PENAWARAN UMUM PERDANA (IPO)`,
						content: `Penawaran umum perdana (IPO) terjadi ketika perusahaan pertama kali menjual saham biasa kepada investor di masyarakat. Ketika perusahaan mulai menawarkan sahamnya melalui IPO, mereka diharuskan untuk mengungkapkan informasi keuangan dan semua informasi material yang relevan tentang perusahaan sehingga investor dapat membuat keputusan yang tepat.`
					},
					{
						name: `PERGERAKAN INTRADAY (DALAM SEHARI)`,
						content: `Intraday didefinisikan sebagai dalam sehari. Pergerakan intraday mengacu pada pergerakan harga efek tertentu selama satu hari aktivitas perdagangan selama jam perdagangan reguler. Hal ini untuk memperlihatkan harga tinggi dan rendah dari efek di hari perdagangannya.`
					},
					{
						name: `PENERBIT`,
						content: `Entitas yang menerbitkan waran terstruktur, biasanya lembaga keuangan, misalnya Maybank Sekuritas.`
					},
					{
						name: `PENYEDIA LIKUIDITAS`,
						content: `Lihat PEMBUAT PASAR.`
					},
					{
						name: `PEMBUAT PASAR`,
						content: `Pembuat pasar menyediakan likuiditas dengan memposting harga permintaan beli dan penawaran jual pada sistem perdagangan Bursa Efek Indonesia pada setiap hari pasar selama masa berlaku waran terstruktur. Pembuat pasar dapat merupakan penerbit itu sendiri (misalnya Maybank Sekuritas) atau pembuat pasar yang ditentukan.`
					},
					{
						name: `PREMI`,
						content: `Mengukur berapa persentase aset yang mendasari harus naik (Waran Jenis <i>Call</i>) atau turun (Waran Jenis <i>Put</i>) supaya pemegang waran terstruktur dapat mencapai titik keseimbangan jika ia memegang waran terstruktur sampai tanggal pelaksanaan.`
					},
					{
						name: `PROSPEKTUS`,
						content: `Dokumen yang akan diterbitkan oleh perusahaan yang bermaksud untuk menerbitkan saham kepada publik.	`
					},
					{
						name: `PEMECAHAN SAHAM`,
						content: `Ketika suatu perusahaan mengurangi nilai nominal sahamnya dengan meningkatkan jumlah saham yang beredar di pasar, misalnya 100.000 unit saham biasa dengan harga Rp500 akan dipecah menjadi 200.000 unit saham biasa dengan harga Rp250.`
					},
					{
						name: `PEMEGANG WARAN TERSTRUKTUR`,
						content: `Masyarakat yang berinvestasi dalam waran terstruktur dan namanya tercatat dalam daftar pemegang waran terstruktur.`
					},
					{
						name: `PROSPEKTUS WARAN TERSTRUKTUR`,
						content: `Dokumen penawaran yang diterbitkan oleh Penerbit sehubungan dengan penawaran waran terstruktur yang akan diterbitkan dari waktu ke waktu.`
					},
					{
						name: `PELURUHAN WAKTU`,
						content: `Peluruhan waktu adalah efek dari penurunan nilai waktu karena waran terstruktur bergerak menuju tanggal pelaksanaan.`
					},
					{
						name: `PENYANGKALAN`,
						content: `Anda setuju bahwa penggunaan Layanan adalah atas risiko Anda sendiri dan mengakui bahwa Layanan dan apa pun yang dimuat dalam Layanan diberikan "sebagaimana adanya" dan PT Maybank Sekuritas Indonesia ("<strong>Maybank Sekuritas</strong>") tidak membuat pernyataan atau jaminan dalam bentuk apa pun, secara tegas maupun tersirat, mengenai kelayakan untuk diperdagangkan, ketepatan waktu, keakuratan, tidak adanya pelanggaran, hak kepemilikan atau kesesuaian untuk tujuan atau penggunaan tertentu atau pernyataan atau jaminan apa pun yang timbul dari penggunaan atau kebiasaan atau perdagangan atau dengan pelaksanaan hukum.<br/><br/>
                                                                                                                                                                                                                                                                                                                                        
						Maybank Sekuritas, karyawan, direktur, agen, penyedia layanan dan Penyedia Konten Pihak Ketiga-nya tidak bertanggung jawab atas kehilangan data apa pun, termasuk kerusakan yang tidak disengaja, penghapusan data yang ditransmisikan atau diterima atau disimpan di Situs ini, kesalahan, kealpaan, gangguan atau penghapusan file, eror, cacat, keterlambatan operasi, atau transmisi, atau kegagalan kinerja, baik yang dibatasi atau tidak dibatasi pada Bencana Alam, kegagalan komunikasi, pencurian, penghancuran atau akses tidak sah ke catatan Maybank Sekuritas atau Situs. <br/><br/>
																																																																																																																																				
						Dalam hal apa pun Maybank Sekuritas, karyawan, direktur, agen, penyedia layanan atau Penyedia Konten Pihak Ketiga-nya tidak bertanggung jawab berkenaan dengan klaim apa pun yang timbul dari atau terkait dengan Situs, Layanan, atau Kontennya, termasuk, tetapi tidak terbatas pada, (i) setiap keputusan yang dibuat atau tindakan yang diambil oleh pengguna mana pun dengan mengandalkan Konten tersebut,  (ii) setiap akses, penggunaan atau ketidakmampuan untuk mengakses atau menggunakan Situs ini, (iii) kegagalan sistem atau server atau koneksi, kesalahan atau kealpaan, gangguan, keterlambatan transmisi, atau virus komputer; dan/atau (iv) setiap penggunaan atau akses ke situs web lain yang ditautkan ke Situs ini, atau untuk setiap kerugian yang dialami, baik secara langsung, konsekuensial, khusus, yang bersifat menghukum, tidak langsung atau lainnya, terlepas telah diberitahukan mengenai kemungkinan kerugian tersebut.<br/><br/>
																																																																																																																																																																														
						Klausul pengecualian ini akan berlaku sejauh yang diizinkan oleh hukum.			`
					},
					]
				},
				{
					letter: 'Q',
					data: []
				},
				{
					letter: 'R',
					data: [{
						name: `REKENING DANA NASABAH`,
						content: `Rekening Dana Nasabah, yang biasa dikenal sebagai Rekening Dana Nasabah ("RDN") adalah rekening dana atas nama nasabah bank yang ditunjuk oleh PT Kustodian Sentral Efek Indonesia ("KSEI") sesuai dengan peraturan perundang-undangan yang berlaku, yang dikelola oleh Perantara Pedagang Efek berdasarkan surat kuasa atau instruksi dari nasabah.`
					},
					{
						name: `RASIO KONVERSI`,
						content: `Jumlah waran terstruktur yang perlu untuk ditukar dengan satu unit aset yang mendasarinya.<br/> <br/>
						Misalnya, waran  jenis <i>call</i> XYZ dengan rasio 5 waran jenis <i>call</i>: 1 saham XYZ akan menunjukkan bahwa 5 call waran beli jenis call diperlukan untuk melaksanakan hak untuk membeli satu saham XYZ pada harga pelaksanaan.<br/><br/>
						Untuk waran terstruktur yang terkait dengan harga saham, rasio tersebut dapat disesuaikan untuk mencerminkan aksi korporasi, seperti penerbitan saham bonus, pemecahan saham, hak memesan efek terlebih dahulu (rights issue) atau konsolidasi saham.	`
					},
					]
				},
				{
					letter: 'S',
					data: [{
						name: `SAHAM BONUS`,
						content: `Saham bonus adalah saham yang dibagikan oleh perusahaan kepada pemegang sahamnya saat ini sebagai saham yang disetor penuh secara gratis. Sebagai ganti pembayaran dividen, perusahaan menawarkan untuk membagikan saham tambahan kepada pemegang saham. Misalnya, perusahaan dapat memutuskan untuk memberikan satu saham bonus untuk setiap sepuluh saham yang dimiliki oleh pemegang saham yang ada.`
					},
					{
						name: `SAHAM PERTUMBUHAN`,
						content: `Saham perusahaan dengan prospek yang baik untuk meningkatkan keuntungan dan ukuran modal. Kemungkinan akan membawa keuntungan modal di masa mendatang bagi pemegang saham melalui kenaikan harga saham, dividen tinggi, bonus saham, atau hak untuk memesan efek terlebih dahulu (rights issue).`
					},
					{
						name: `<i>SHORT SELLING</i>`,
						content: `Tindakan seseorang menjual efek yang tidak dimilikinya pada saat ia menjual.`
					},
					{
						name: `<i>STOP ORDER</i>`,
						content: `<i>Stop order</i> menjadi market order untuk membeli atau menjual efek pada saat harga stop yang ditentukan tercapai atau ditembus.`
					},
					]
				},
				{
					letter: 'T',
					data: [

						{
							name: `TINGKAT TITIK KESEIMBANGAN`,
							content: `Merujuk kepada harga yang pada harga tersebut transaksi tidak menghasilkan keuntungan atau kerugian.`
						},
						{
							name: `JUMLAH PENYELESAIAN TUNAI`,
							content: `Jumlah positif yang diterima dari pelaksanaan waran terstruktur pada saat jatuh tempo. Dalam hal jumlah penyelesaian tunai kurang dari atau sama dengan nol, pemegang waran terstruktur tidak berhak menerima pembayaran apa pun berkenaan dengan waran terstruktur.				`
						},
						{
							name: `tanggal pelaksanaan`,
							content: `tanggal pelaksanaannya waran terstruktur; di mana hak pemegang waran terstruktur untuk melaksanakan waran berakhir.`
						},
						{
							name: `THETA`,
							content: `Mengukur penurunan harian yang diperkirakan dalam nilai waran terstruktur karena peluruhan waktu, semua faktor lainnya tetap konstan. Theta selalu negatif karena peluruhan waktu selalu bekerja melawan pemegang waran terstruktur.	`
						},
					]
				},
				{
					letter: 'U',
					data: []
				},
				{
					letter: 'V',
					data: [
						{
							name: `VOLUME PERMINTAAN`,
							content: `Volume permintaan adalah jumlah efek yang bersedia dijual penjual pada harga permintaan tertentu. Volume permintaan dinyatakan sebagai lot yang setiap daripadanya mewakili 100 unit masing-masing.`
						},
						{
							name: `VOLUME PENAWARAN`,
							content: `Volume penawaran adalah jumlah efek yang bersedia dibeli pembeli pada harga penawaran tertentu. Volume penawaran dinyatakan sebagai lot yang setiap daripadanya mewakili 100 unit masing-masing.`
						},
						{
							name: `VOLATILITAS HISTORIS`,
							content: `Digunakan sebagai acuan untuk menilai fluktuasi harga sebelumnya dari aset yang mendasarinya. Biasanya dinyatakan dalam jumlah hari, misalnya volatilitas 10 hari, volatilitas 90 hari, dll.`
						},
						{
							name: `VOLATILITAS TERSIRAT`,
							content: `Faktor terpenting yang mempengaruhi harga waran terstruktur. Volatilitas tersirat merujuk kepada perkiraan volatilitas harga di masa mendatang dari aset yang mendasarinya yang ditentukan dan digunakan oleh pasar sebagai indikator untuk memutuskan apakah waran terstruktur itu murah atau mahal. Dengan semua faktor yang sama, semakin tinggi volatilitas tersirat, semakin tinggi harga waran terstruktur.<br/><br/>
							Misalnya, waran terstruktur pada harga IDR500 yang berfluktuasi pada harga IDR50 memiliki volatilitas tersirat yang lebih tinggi daripada waran terstruktur pada harga IDR500 yang berfluktuasi pada harga IDR5.`
						},
						{
							name: `VOLATILITAS`,
							content: `Ketidakpastian dalam pergerakan harga yang akan mempengaruhi pengembalian dari aset yang mendasarinya. <br/><br/>Lihat VOLATILITAS HISTORIS dan VOLATILITAS TERSIRAT	`
						},
						{
							name: `VOLUME YANG DIPERDAGANGKAN`,
							content: `Volume yang diperdagangkan merujuk kepada jumlah total efek tertentu yang ditransaksikan antara pembeli dan penjual selama hari perdagangan sepanjang jam perdagangan regulernya.	`
						},
					]
				},
				{
					letter: 'W',
					data: [{
						name: `WARAN (WARRANTS)`,
						content: `Waran memberikan pemegang hak tetapi bukan kewajiban untuk membeli sekuriti yang mendasari pada harga, kuantiti tertentu.`
					},

					{
						name: `WAKTU PERDAGANGAN WARAN (WARRANTS TRADING HOURS)`,
						content: `Waktu dagangan waran untuk saham FBM KLCI dan saham di Malaysia adalah dari jam 9.00 pagi - 4.44 petang, dan 9.00 pagi - 4.43 petang untuk waran untuk S&P 500.`
					},
					{
						name: `WARAN PANGGILAN (CALL WARRANTS)`,
						content: `Waran panggilan menawarkan kepada pemegangnya hak, tetapi bukan kewajiban untuk membeli sejumlah aset yang mendasari pada harga laksana yang telah ditentukan dalam waktu yang ditentukan sebelumnya.`
					},
					{
						name: `WAKTU PERDAGANGAN EKUITI (EXCHANGE TRADING HOURS)`,
						content: `Waktu perdagangan ekuiti dibahagikan kepada dua sesi untuk pasaran lot biasa dan ganjil.<br/>
                                Untuk Malaysia: Sesi pertama adalah dari 8:30 pagi - 12:30 petang, dan sesi kedua berlangsung dari 2:00 petang - 5:00 petang.<br/>
                                Untuk Hong Kong: Sesi pertama adalah dari 9:00 pagi - 12:00 petang, dan sesi kedua berlangsung dari 1:00 petang - 4:00 petang.<br/>
                                Untuk Thailand: Sesi pertama dibuka secara rawak antara jam 9:55 hingga 10:00 am - 12:30 pm, dan sesi kedua dibuka secara rawak antara 2:25 hingga 2:30 pm dan ditutup secara rawak antara jam 4:35 hingga 4:40 pm.`
					},
					{
						name: `WARAN PUT`,
						content: `Waran put menawarkan kepada pemegangnya hak, tetapi bukan kewajiban untuk menjual sejumlah aset yang mendasari pada harga laksana yang telah ditentukan dalam waktu yang ditentukan sebelumnya.`
					}, {
						name: `WARAN`,
						content: `Waran memberikan pemegang hak tetapi bukan kewajiban untuk membeli atau menjual sekuriti yang mendasari pada harga, kuantiti tertentu.`
					},
					{
						name: `WARAN JENIS <i>PUT</i>`,
						content: `Waran jenis <i>put</i> menawarkan hak kepada pemegangnya, tetapi bukan kewajiban untuk menjual sejumlah aset yang mendasari tertentu pada harga pelaksanaan yang telah ditentukan sebelumnya dalam waktu yang telah ditentukan sebelumnya.`
					},
					{
						name: `WARAN TERSTRUKTUR`,
						content: `Efek yang memberikan pemegang waran terstruktur hak untuk membeli atau menjual aset yang mendasari pada harga dan waktu yang telah ditentukan sebelumnya.`
					},
					{
						name: `WARAN`,
						content: `Waran memberikan pemegangnya hak tetapi bukan kewajiban untuk membeli efek yang mendasarinya pada harga dan waktu tertentu.`
					}
					]
				},
				{
					letter: 'X',
					data: []
				},
				{
					letter: 'Y',
					data: []
				},
				{
					letter: 'Z',
					data: []
				}
				]
			],
			GlossarySeek: [],
			dataindex: 0,
			letter: '',
			indexN: 0
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 改变语言
		ChangeLanguage: function (index) {
			$('#Glossary').find('.btnBox').find('button').eq(index).addClass('mark')
			$('#Glossary').find('.btnBox').find('button').eq(index).siblings('button').removeClass('mark')
			this.dataindex = index
			this.relevantData(this.letter, this.indexN)
			this.GitGlossarySeek(this.GlossaryData[index])
		},
		// 点击显示相应数据
		relevantData: function (letter, index) {
			this.indexN = index
			this.letter = letter
			$('#Glossary').find('.content').find('li').find('p').slideUp();
			$('#Glossary').find('.content').find('li').find('i').addClass('bi-caret-down-fill')
			$('#Glossary').find('.content').find('li').find('i').removeClass('bi-caret-up-fill')
			$('#Glossary').find('.letterBox').find('th').eq(index).addClass('markth')
			$('#Glossary').find('.letterBox').find('th').eq(index).siblings('th').removeClass('markth')
			this.GlossaryShwoData = this.GlossaryData[this.dataindex].filter(item => item.letter == letter)
			this.GlossaryShwoData = this.GlossaryShwoData[0].data
		},
		// 获得 搜索框数据
		GitGlossarySeek: function (data) {
			this.GlossarySeek = []
			$.each(data, (i, c) => {
				$.each(c.data, (di, dc) => {
					this.GlossarySeek.push([dc.name, i, di])
				})
			})
		},
		// 详细内容显示和隐藏
		minuteShow: function (index, ife) {
			if ($('#Glossary').find('.content').find('li').eq(index).find('i').hasClass('bi-caret-up-fill')) {
				$('#Glossary').find('.content').find('li').eq(index).find('i').addClass('bi-caret-down-fill')
				$('#Glossary').find('.content').find('li').eq(index).find('i').removeClass('bi-caret-up-fill')
			} else {
				$('#Glossary').find('.content').find('li').eq(index).find('i').addClass('bi-caret-up-fill')
				$('#Glossary').find('.content').find('li').eq(index).find('i').removeClass('bi-caret-down-fill')
			}
			console.log();
			if (ife) {
				$('#Glossary').find('.content').find('li').eq(index).find('p').slideToggle();
			} else {
				let win = $(window)
				setTimeout(function () {
					$('#Glossary').find('.content').find('li').eq(index).find('p').slideToggle();
					win.scrollTop($('#Glossary').find('.content').find('li').eq(index).offset().top)
				}, 10);
			}

		},
		// 出现combobox
		ariseCombobox: function (id, index) {
			let _this = this
			this.$$.jqcombobox(id)
			$(id).combobox({
				select: function (event, ui) {
					_this.relevantData(this.value.split(',')[0].split('')[0], this.value.split(',')[1])
					_this.minuteShow(this.value.split(',')[2])
				}
			});
			$(id).parent().find('input').attr('placeholder', 'Type to Search')
			this.$$.inputEmpty($(id).parent())
		},
	}
};